<template>
  <v-container
    fluid
    grid-list-lg
    fill-height
    id="home"
    class="py-2"
  >
    <v-layout column>
      <v-flex xs12>
        <v-card
          flat
          class="border-round"
        >
          <v-card-title>
            <div class="d-flex justify-space-between" style="width: 100%;">
              {{ partname }}
              <v-btn text color="primary" :to="{name: 'fileManager'}">
                <v-icon left dark>mdi-keyboard-backspace</v-icon>
                Back
              </v-btn>
            </div>
          </v-card-title>
          <v-card-text id="home-container">
            <v-data-table
              :headers="headers"
              :items="sops"
              :items-per-page="10"
              class="elevation-1"
              :loading="loading"
            >
              <!-- eslint-disable-next-line -->
              <template #item.action="{ item }">
                <v-dialog
                  v-model="item.dialog"
                  max-width="600px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-eye-outline
                          </v-icon>
                        </template>
                        <span>Preview</span>
                      </v-tooltip>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">
                        {{ item.filename }}
                      </span>
                    </v-card-title>
                    <v-card-text>
                      <div
                        class="d-flex justify-center pa-5"
                        v-if="fileloading && item.extension === 'pdf'"
                      >
                        <v-progress-circular indeterminate color="primary" />
                      </div>
                      <!-- {{ showFile(item.downloadlink) }} -->
                      <span v-if="item.extension === 'pdf'">
                        <pdf
                          :src="`${item.url}`"
                          @num-pages="pdfPages = $event"
                          v-show="false"
                          v-if="item.dialog"
                          style="width: 100%; margin: 0 auto; height: 100%;"
                        />
                        <pdf
                          :src="`${item.url}`"
                          style="width: 100%; margin: 0 auto; height: 100%;"
                          v-for="i in pdfPages"
                          :page="i"
                          :key="i"
                          @progress="fileloading = true"
                          @loaded="fileloading = false"
                        />
                      </span>
                      <video
                        width="550"
                        height="240"
                        controls
                        v-else-if="item.extension === 'mp4' && item.dialog"
                      >
                        <source :src="`${item.url}`" type="video/mp4">
                      </video>
                      <img
                        :src="item.url"
                        alt="layout"
                        height="auto"
                        width="100%"
                        class="sopimg"
                        ref="layout"
                        v-else
                      />
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="item.dialog = false"
                      >
                        Close
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="red"
                      v-bind="attrs"
                      v-on="on"
                      @click="handleDelete(item._id)"
                    >
                      <v-icon>
                        mdi-trash-can-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import pdf from 'vue-pdf';

export default {
  name: 'Preview',
  components: {
    pdf,
  },
  data() {
    return {
      pdfPages: 1,
      headers: [
        {
          text: 'File Name',
          align: 'start',
          sortable: false,
          value: 'filename',
        },
        {
          text: 'File Type',
          align: 'start',
          sortable: false,
          value: 'extension',
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'action',
        },
      ],
      sops: [],
      loading: false,
      fileloading: false,
      countpages: true,
    };
  },
  computed: {
    ...mapState('fileManager', ['selectedTab']),
    partname() {
      return this.$route.query.partname;
    },
    machinename() {
      return this.$route.query.machinename;
    },
    selectedTab() {
      return this.$route.query.tab;
    },
    module() {
      return this.$route.query.module;
    },
  },
  mounted() {
    this.fetchFileList();
  },
  methods: {
    ...mapActions('fileManager', ['getSOPs', 'getFile', 'deleteFile']),
    ...mapMutations('helper', ['setAlert']),
    async fetchFileList() {
      this.loading = true;
      let query = `?query=type=="${this.selectedTab}"%26%26module=="${this.module}"`;
      if (this.machinename) {
        // eslint-disable-next-line
        query += `%26%26machinename=="${this.machinename}"`;
      }
      if (this.partname) {
        // eslint-disable-next-line
        query += `%26%26partname=="${this.partname}"`;
      }
      const data = await this.getSOPs(query);
      const result = [];
      if (data) {
        await Promise.all(data.map(async (d) => {
          const url = await this.getFile(d.downloadlink);
          const obj = {
            ...d,
            url,
          };
          result.push(obj);
        }));
      }
      this.sops = result;
      this.loading = false;
    },
    async handleDelete(id) {
      const res = await this.deleteFile(id);
      if (res) {
        this.setAlert({
          show: true,
          type: 'success',
          message: 'DELETE_RECORD',
        });
      } else {
        this.setAlert({
          show: true,
          type: 'error',
          message: 'ERROR_DELETE_RECORD',
        });
      }
      this.fetchFileList();
    },
  },
};
</script>

<style lang="scss" scoped>
.border-round {
  border-radius: 10px;
}
</style>
